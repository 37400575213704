/* eslint-disable jsx-a11y/anchor-is-valid */
import tw, { css } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/util/Layout";
import SEO from "../components/util/SEO";
import NavLayout from "../components/layout/nav-layout";

const MediaKitPage = () => {
	return (
		<Layout>
			<SEO />
			<NavLayout>
				<div
					css={[
						// tw`min-h-screen`,
						tw`font-display text-bitters`,
						tw`bg-light-beige`,
						tw`px-6 pt-24 pb-8 lg:(pt-52 px-60 flex flex-col items-center)`,
						tw`flex justify-center`,
					]}
				>
					<div tw="max-w-landing-page  md:(grid grid-template-columns[1fr 1fr]) lg:width[67vw]">
						<div tw=" md:(flex justify-center p-4)">
							<h1 tw="text-4xl font-sticker md:(width[330px] mb-6)">
								Media Kit
							</h1>
						</div>
						<div tw="py-4 flex justify-center md:px-4">
							<p tw="text-2xl md:width[330px]">
								Use these assets to encourage your customers
								to take action. Download signage to print and
								display at your distillery as well as assets
								to use on your social media feeds.
							</p>
						</div>
					</div>
				</div>
				<div
					css={[
						// tw`min-h-screen`,
						tw`font-display text-white`,
						tw`bg-oat`,
						tw`px-6 py-8 flex justify-center`,
					]}
				>
					<div tw="flex flex-col items-center w-full md:(grid grid-template-columns[1fr 1fr]) lg:(max-w-landing-page width[67vw])">
						<div tw="flex flex-col w-full">
							<div tw="flex flex-col items-center">
								<div tw="lg:width[400px] background-color[gray] overflow-hidden w-full mb-6 flex justify-center items-start md:(max-width[330px])">
									<StaticImage
										src="../assets/images/signage-thumbnail.png"
										alt="a post with the Ship My Spirits logo"
										tw="w-full"
									/>
								</div>
								<a
									href="/downloads/signage/Ship-My-Spirits-Print-Materials.zip"
									tw="uppercase bg-bourbon px-4 pt-3 pb-1.5 rounded-lg max-width[300px]"
									download="Ship-My-Spirits-Print-Materials.zip"
									css={[
										tw`uppercase text-light-beige bg-bourbon px-3 pt-3 pb-1.5 rounded-xl font-medium  lg:(min-height[40px])`,
									]}
								>
									Download Print Materials
								</a>
							</div>
						</div>
						<div tw="flex flex-col mt-8 w-full md:mt-0">
							<div tw=" w-full flex flex-col items-center">
								<div tw="lg:width[400px] background-color[gray] overflow-hidden  w-full mb-6 flex justify-center items-start md:(max-width[330px])">
									<StaticImage
										src="../assets/images/social-downloads-thumbnail.png"
										alt="a post with the Ship My Spirits logo"
										tw="w-full"
									/>
								</div>
								<a
									href="/downloads/social/Ship-My-Spirits-Social-Media-Assets.zip"
									tw="uppercase bg-bourbon px-4 pt-3 pb-1.5 rounded-lg max-width[300px]"
									download="Ship-My-Spirits-Social-Media-Assets.zip"
									css={[
										tw`uppercase text-light-beige bg-bourbon px-3 pt-3 pb-1.5 rounded-xl font-medium  lg:(min-height[40px])`,
									]}
								>
									Download Social Assets
								</a>
							</div>
						</div>
					</div>
				</div>
			</NavLayout>
		</Layout>
	);
};

export default MediaKitPage;
